import runtime from '@adobe/exc-app';

import {
  getRegionFromIMSProfile,
  getWorkfrontSubDomainFromIMSProfile,
} from './getIntermediaryHeaders';

export const getAdobeIOConfigForGenstudio = () => {
  const excApp = runtime();
  const env = excApp.lastConfigurationPayload?.environment;
  const isProdEnv = env === 'prod';
  return {
    token: excApp.lastConfigurationPayload?.imsToken ?? '',
    subdomain: getWorkfrontSubDomainFromIMSProfile() || getRegionFromIMSProfile(),
    imsOrg: excApp.lastConfigurationPayload?.imsOrg ?? '',
    product: 'genstudio',
    useDev: !isProdEnv,
  };
};
