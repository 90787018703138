import { MaestroError } from '../errors';

export const handleErrorResponse = (response: Response) =>
  response
    .json()
    .catch((error) => {
      throw new Error(response.statusText, { cause: error });
    })
    .then((errorBody) => {
      if (errorBody.type) {
        throw new MaestroError(
          errorBody.type,
          errorBody.report?.conflictHandlingDataList ?? null,
          errorBody.report?.messageArguments
        );
      }
      throw new Error(response.statusText);
    });
