import { LicenseType } from '../types/LicenseType';

export enum UserType {
  WF = 'WF',
}

export interface IUser {
  id: string;
  name: string;
  isActive: boolean;
  isAdmin: boolean;
  email: string;
  userType: UserType;
  avatarDownloadURL: string;
  licenseType: LicenseType;
  role: {
    ID: string;
    name: string;
  };
}

export interface IRRObjectUsersListResponse {
  [objId: string]: IUser[];
}
