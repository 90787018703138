import {
  IBulkRecordDTO,
  IBulkResponse,
  IConnectionRecordSearchDTO,
  IImportRecordPayload,
  IRecentlyDeletedRecord,
  IRecord,
  IRecordActivityLogItem,
  IRecordSearchDTO,
  IRecordTypeRecordsCount,
  ISearchRecordsConnectionResponse,
  ISearchRecordsResponse,
  RecordDTO,
} from '../types/Record';
import BaseCrudApi from './BaseCrudApi';
import { RequestMethod } from '../types/RequestMethod';
import { ApiClientFetchOptions } from './AbstractApiClient';
import { Cacheable } from '../cache/Cacheable';
import { emitter } from '../emitter';
import { ImageTypes } from '../enums/imageTypes';

export default class RecordAPI extends BaseCrudApi<IRecord> {
  protected ENDPOINT = 'records';
  protected BULK_ENDPOINT = 'records/bulk';
  protected CONNECTION_ENDPOINT = 'records/connection';
  protected COUNT_ENDPOINT = 'records/count';
  protected CHANGE_HISTORY_ENDPOINT = 'history/record';
  protected RECENTLY_DELETED = 'records/recentlyDeleted';
  protected IMPORT_ENDPOINT = 'records/import';

  create(record?: RecordDTO): Promise<IRecord> {
    return this.client.post<IRecord>(this.ENDPOINT, record);
  }

  update(
    id: string,
    updateData: Partial<IRecord>,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<IRecord> {
    return this.client.patch<IRecord>(
      `${this.ENDPOINT}/${id}`,
      updateData,
      fetchOptions
    ) as Promise<IRecord>;
  }

  getAll(recordTypeId: string, recordIds?: string[], attributes?: string[]): Promise<IRecord[]> {
    const params = new URLSearchParams();
    params.set('recordTypeId', recordTypeId);
    recordIds && params.set('recordIds', recordIds.toString());
    attributes && params.set('attributes', attributes.toString());
    return this.client.get<IRecord[]>(`${this.ENDPOINT}?${params.toString()}`);
  }

  searchRecords(
    recordSearch: IRecordSearchDTO,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ISearchRecordsResponse> {
    return this.client
      .fetch<ISearchRecordsResponse>(
        `${this.ENDPOINT}/search`,
        RequestMethod.POST,
        recordSearch,
        fetchOptions
      )
      .then((data) => ({
        ...data,
        groups: data.groups.map((group) => ({
          ...group,
          value: Array.isArray(group.value) ? group.value.flat(Infinity) : group.value,
        })),
      }));
  }

  searchAliasedRecords(
    recordSearch: IRecordSearchDTO,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ISearchRecordsResponse> {
    return this.client.fetch<ISearchRecordsResponse>(
      `${this.ENDPOINT}/search?aliased=true`,
      RequestMethod.POST,
      recordSearch,
      fetchOptions
    );
  }

  uploadThumbnail(
    recordId: string,
    body: FormData
  ): Promise<{ thumbnailUrl: IRecord['thumbnailUrl'] }> {
    return this.client.post(`${this.ENDPOINT}/${recordId}/thumbnail`, body);
  }

  updateImage(
    recordId: string,
    type: ImageTypes,
    body: FormData,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<{ url: IRecord['thumbnailUrl'] }> {
    return this.client.post(`${this.ENDPOINT}/${recordId}/image/${type}`, body, fetchOptions);
  }

  exportToWord(recordId: string): Promise<Blob> {
    return this.client.get<Blob>(`${this.ENDPOINT}/${recordId}`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    });
  }

  uploadCover(recordId: string, body: FormData): Promise<{ coverUrl: string }> {
    return this.client.post(`${this.ENDPOINT}/${recordId}/cover`, body);
  }

  editCover(recordId: string, position: number): Promise<{ position: number }> {
    return this.client.put(`${this.ENDPOINT}/${recordId}/cover`, position);
  }

  removeThumbnail(recordId: string, fetchOptions?: ApiClientFetchOptions): Promise<void> {
    return this.client.delete(`${this.ENDPOINT}/${recordId}/thumbnail`, undefined, fetchOptions);
  }

  removeCover(recordId: string): Promise<void> {
    return this.client.delete(`${this.ENDPOINT}/${recordId}/cover`);
  }

  bulkOperation(
    data: IBulkRecordDTO,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<IBulkResponse> {
    return this.client
      .post<IBulkResponse>(this.BULK_ENDPOINT, data, fetchOptions)
      .then((response) => {
        // TODO: remove when maestro-gen-studio support RTBE
        emitter.emit('RECORD_BULK_OPERATION_RESPONSE', {
          ...response,
          deletedIds: data['recordIdsToDelete'] || [],
        });
        return response;
      });
  }

  connectionSearchRecords(
    recordSearch: IConnectionRecordSearchDTO,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ISearchRecordsConnectionResponse> {
    return this.client.fetch<ISearchRecordsConnectionResponse>(
      `${this.CONNECTION_ENDPOINT}/search`,
      RequestMethod.POST,
      recordSearch,
      fetchOptions
    );
  }

  getSearchHistory(
    id: string,
    offset: number,
    limit: number
  ): Promise<Array<IRecordActivityLogItem<unknown>>> {
    return this.client.get(
      `${this.CHANGE_HISTORY_ENDPOINT}/${id}?${new URLSearchParams({
        offset: `${offset}`,
        limit: `${limit}`,
      }).toString()}`
    );
  }

  /**
   * Get the records count for single record type without cache
   */
  recordsCount(recordTypeIds: string): Promise<number>;
  /**
   * Get the records count for specified record types
   * uses Cache
   */
  recordsCount(recordTypeIds: string[]): Promise<IRecordTypeRecordsCount>;
  recordsCount(recordTypeIds: string[] | string) {
    if (typeof recordTypeIds === 'string') {
      return this.recordsCountSingle(recordTypeIds);
    }
    return this.recordsCountBulk(recordTypeIds);
  }

  @Cacheable('STALE_WHILE_REVALIDATE')
  protected async recordsCountBulk(recordTypeIds: string[]): Promise<IRecordTypeRecordsCount> {
    return this.client.get<IRecordTypeRecordsCount>(
      `${this.COUNT_ENDPOINT}?recordTypeIds=${recordTypeIds}`
    );
  }

  protected async recordsCountSingle(recordTypeId: string): Promise<number> {
    return this.client
      .get<IRecordTypeRecordsCount>(`${this.COUNT_ENDPOINT}?recordTypeIds=${recordTypeId}`)
      .then((response) => response[recordTypeId]);
  }

  getImageUrl(url: string): Promise<string> {
    return this.client
      .get<Blob>(`${this.client.attachmentsPrefix}${url}`, {
        cache: 'force-cache',
      })
      .then((blob) => URL.createObjectURL(blob));
  }

  getRecentlyDeleted(recordTypeId: string): Promise<IRecentlyDeletedRecord[]> {
    return this.client.get<IRecentlyDeletedRecord[]>(
      `${this.RECENTLY_DELETED}?recordTypeId=${recordTypeId}`
    );
  }

  import(body: IImportRecordPayload): Promise<IRecord[]> {
    return this.client.post(this.IMPORT_ENDPOINT, body);
  }
}
