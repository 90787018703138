import BaseCrudApi from './BaseCrudApi';
import { IGetRelationsResponse, IObjectRelationDto, IRelations, IUser } from '../types';

export default class RelationsAPI extends BaseCrudApi<IUser> {
  protected ENDPOINT = 'relations';
  async getWorkspaceRelations(workspaceId: string): Promise<IObjectRelationDto[]> {
    return this.client.get<IObjectRelationDto[]>(`${this.ENDPOINT}/workspace/${workspaceId}`);
  }

  async modifyWorkspaceRelations(workspaceId: string, relations: IRelations): Promise<IRelations> {
    return this.client.post<IRelations>(`${this.ENDPOINT}/workspace/${workspaceId}`, relations);
  }

  async getViewRelations(viewId: string): Promise<IGetRelationsResponse> {
    return this.client.get<IGetRelationsResponse>(`${this.ENDPOINT}/view/${viewId}`);
  }

  async modifyViewRelations(viewId: string, relations: IRelations): Promise<IRelations> {
    return this.client.post<IRelations>(`${this.ENDPOINT}/view/${viewId}`, relations);
  }

  async modifyRecordTypeRelations(
    recordTypeId: string,
    relations: IRelations
  ): Promise<IRelations> {
    return this.client.post<IRelations>(`${this.ENDPOINT}/recordType/${recordTypeId}`, relations);
  }

  async getRecordTypeRelations(recordTypeId: string): Promise<IObjectRelationDto[]> {
    return this.client.get<IObjectRelationDto[]>(`${this.ENDPOINT}/recordType/${recordTypeId}`);
  }
}
