export enum AuthzObjectType {
  WORKSPACE = 'maestro/workspace',
  RECORD_TYPE = 'maestro/recordtype',
  REDROCK_USER = 'redrock/user',
  REDROCK_GROUP = 'redrock/group',
  REDROCK_COMPANY = 'redrock/cmpy',
  REDROCK_TEAM = 'redrock/teamob',
  REDROCK_ROLE = 'redrock/role',
  IMS_USER = 'ims/user',
}
