import {
  FieldDTO,
  IConnection,
  IConnectionField,
  IConnectionFilter,
  IConnectionImportFilter,
  IConnectionObjectType,
  IRecord,
  ISharingData,
} from '../types';
import { AbstractApiClient } from './AbstractApiClient';

export default class ConnectionAPI {
  protected client: AbstractApiClient;
  protected ENDPOINT = 'connections';
  protected METADATA_ENDPOINT = 'connections/metadata';
  protected IMPORT_ENDPOINT = 'connections/import';

  constructor(client: AbstractApiClient) {
    this.client = client;
  }

  getAll(): Promise<IConnection[]> {
    return this.client.get<IConnection[]>(`${this.ENDPOINT}`);
  }

  getConnectionObjectMetadata<T = unknown>(
    connectionName: string,
    objectName: string,
    metadataName: string
  ) {
    return this.client.get<T>(
      `${this.METADATA_ENDPOINT}?${new URLSearchParams({
        connectionName,
        objectName,
        metadataName,
      }).toString()}`
    );
  }

  getFilters(connectionName: string, objectName: string): Promise<IConnectionFilter[]> {
    return this.client.get<IConnectionFilter[]>(
      `${this.ENDPOINT}/filters?${new URLSearchParams({ connectionName, objectName })}`
    );
  }

  getFields(connection: string, object: string): Promise<IConnectionField[]> {
    return this.client.get<IConnectionField[]>(
      `${this.ENDPOINT}/fields?${new URLSearchParams({ connection, object })}`
    );
  }

  getCreateRecordPermission(connectionName: string, objectName: string): Promise<boolean> {
    return this.client.get<boolean>(
      `${this.ENDPOINT}/has-access?connectionName=${connectionName}&objectName=${objectName}&actionTypeName=ADD`
    );
  }

  createExternalRecord(body: { recordTypeId: string; name: string }): Promise<IRecord> {
    return this.client.post(`${this.ENDPOINT}/create`, body);
  }

  getObjectTypes(connectionName: string): Promise<IConnectionObjectType[]> {
    return this.client.get<IConnectionObjectType[]>(
      `${this.IMPORT_ENDPOINT}/object-types?connectionName=${connectionName}`
    );
  }

  getImportFilters(connectionName: string): Promise<IConnectionImportFilter[]> {
    return this.client.get<IConnectionImportFilter[]>(
      `${this.IMPORT_ENDPOINT}/filters?connectionName=${connectionName}`
    );
  }

  searchImportFields({
    connectionName,
    objectType,
    filters,
    fieldName,
  }: {
    connectionName: string;
    objectType?: string;
    filters?: string[];
    fieldName?: string;
  }): Promise<{ nativeFields: FieldDTO[]; customFields: FieldDTO[] }> {
    const params = new URLSearchParams({ connectionName });

    objectType && params.append('objectType', objectType);
    fieldName && params.append('fieldName', fieldName);

    if (filters && filters.length) {
      filters.forEach((id) => params.append('filters', id));
    }

    return this.client.get<{ nativeFields: FieldDTO[]; customFields: FieldDTO[] }>(
      `${this.IMPORT_ENDPOINT}/search-fields?${params.toString()}`
    );
  }

  async searchSharingData(name?: string): Promise<ISharingData> {
    const params = new URLSearchParams();
    if (name !== undefined) {
      params.set('name', name);
      params.set('connectionName', 'workfront');
    }
    return this.client.get<ISharingData>(`${this.ENDPOINT}/sharing-data?${params.toString()}`);
  }
}
