import { RequestMethod } from '../types/RequestMethod';
import { handleErrorResponse } from '../utils/handleErrorResponse';

export type ApiClientFetchOptions = Omit<RequestInit, 'body' | 'method'>;
export abstract class AbstractApiClient {
  public attachmentsPrefix = '';

  abstract fetch<ResponseType>(
    endpoint: string,
    method: RequestMethod,
    body?: unknown,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ResponseType>;

  get<ResponseType>(endpoint: string, fetchOptions?: ApiClientFetchOptions) {
    return this.fetch<ResponseType>(endpoint, RequestMethod.GET, undefined, fetchOptions);
  }

  post<ResponseType>(endpoint: string, body: unknown, options?: ApiClientFetchOptions) {
    return this.fetch<ResponseType>(endpoint, RequestMethod.POST, body, options);
  }

  put<ResponseType>(endpoint: string, body: unknown) {
    return this.fetch<ResponseType>(endpoint, RequestMethod.PUT, body);
  }

  delete<ResponseType>(endpoint: string, body?: unknown, options?: ApiClientFetchOptions) {
    return this.fetch<ResponseType>(endpoint, RequestMethod.DELETE, body, options);
  }

  patch<ResponseType>(endpoint: string, body: unknown, options?: ApiClientFetchOptions) {
    return this.fetch<Partial<ResponseType>>(endpoint, RequestMethod.PATCH, body, options);
  }

  protected generateRequestId(): string {
    return crypto.randomUUID().replaceAll('-', '');
  }

  protected handleErrorResponse(response: Response) {
    return handleErrorResponse(response);
  }
}
