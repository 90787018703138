import BaseCrudApi from './BaseCrudApi';
import { IRRObjectUsersListResponse, IUser } from '../types';
import { ApiClientFetchOptions } from './AbstractApiClient';

export default class UserAPI extends BaseCrudApi<IUser> {
  protected ENDPOINT = 'users';

  async searchUsers(
    userName?: string,
    userIds?: string[],
    fetchOptions?: ApiClientFetchOptions,
    searchTerm?: string
  ): Promise<IUser[]> {
    const params = new URLSearchParams();

    if (userName !== undefined) {
      params.set('name', userName);
    }

    if (searchTerm !== undefined) {
      params.set('searchTerm', searchTerm);
    }

    if (userIds?.length) {
      params.set('ids', userIds.join(','));
    }

    return this.client.get<IUser[]>(`${this.ENDPOINT}?${params.toString()}`, fetchOptions);
  }

  async getRRObjectUsers(objCode: string, objIds: string[]): Promise<IRRObjectUsersListResponse> {
    return this.client.post<IRRObjectUsersListResponse>(`${this.ENDPOINT}/getRRObjectUsers`, {
      ids: objIds,
      objCode,
    });
  }
}
