import { getCorrelationId } from '@wf-mfe/logger';

import { requestUrl } from '../utils/requestUrl';
import { getGatewayHeader, getIntermediaryHeader } from '../utils/getIntermediaryHeaders';
import { RequestMethod } from '../types/RequestMethod';
import { AbstractApiClient, ApiClientFetchOptions } from './AbstractApiClient';
import { responseHandler } from '../utils/responseHandler';

export default class StandAloneApiClient extends AbstractApiClient {
  constructor(
    /**
     * @param baseURL The base URL of the API.
     * e.g.https://workfront-dev.adobe.io
     */
    public baseURL: string,
    public attachmentsPrefix: string,
    protected version: string,
    protected apiKey: string,
    protected getAuth: () => Promise<{
      token: string;
      org: string;
      profile: Record<string, unknown>;
    }>,
    protected reviewAppId: string | null
  ) {
    super();
  }

  async fetch<ResponseType>(
    endpoint: string,
    method: RequestMethod,
    body?: unknown,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ResponseType> {
    if (fetchOptions && ('body' in fetchOptions || 'method' in fetchOptions)) {
      throw Error(`The "fetchOptions" parameter cannot have properties "body" and "method".`);
    }

    const { token, org, profile } = await this.getAuth();

    const correlationId = getCorrelationId();

    const headers = new Headers({
      ...getIntermediaryHeader(),
      ...getGatewayHeader(),
      Authorization: `Bearer ${token}`,
      'x-gw-ims-org-id': org,
      'x-gw-ims-user-id': profile.userId as string,
      'x-api-key': this.apiKey,
      'x-request-id': this.generateRequestId(),
      ...(correlationId && {
        'x-correlation-id': correlationId,
      }),
      ...fetchOptions?.headers,
    });

    if (!(body instanceof FormData)) {
      headers.append('Content-Type', 'application/json');
    }

    if (this.reviewAppId) {
      headers.append('x-review-app-id', this.reviewAppId);
    }

    const response = await fetch(requestUrl(this.baseURL, 'planning', endpoint), {
      ...fetchOptions,
      method,
      headers,
      body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
    });

    if (response.ok) {
      return responseHandler(response);
    }

    return this.handleErrorResponse(response);
  }
}
