import { IRecordType } from './RecordType';
import { IRecordTypeLayout } from './RecordTypeLayout';
import { IView } from './View';

export interface IContentLayout {
  layout: IRecordTypeLayout;
}
export interface IContentView {
  fieldId: string;
  view: IView;
}

export interface IRecordTypePage {
  id: string;
  recordTypeId: IRecordType['id'];
  displayName: string;
  name: string;
  pageType: PageType;
  content: Record<string, string> | IContentLayout | IContentView;

  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export enum PageType {
  DETAILS = 'DETAILS',
  CONNECTIONS = 'CONNECTIONS',
  CAMPAIGN_CONTENT = 'CAMPAIGN_CONTENT',
  VIEW = 'VIEW',
}

export type RecordTypePageDTO = Pick<IRecordTypePage, 'displayName' | 'recordTypeId' | 'content'> &
  Partial<Pick<IRecordTypePage, 'id'>>;
